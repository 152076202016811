import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import TitleHeader from '../components/titleheader'
import Card from '../components/card'

import headerimage from '../assets/images/whoweare/Corprate_WhoWeAre_01.jpg'
import mbheaderimage from '../assets/images/whoweare/CORPORATE_SITE__HEADER_MB_WHOWEARE.jpg'
import tileimage1 from '../assets/images/whoweare/Corprate_WhoWeAre_02.jpg'
import tileimage2 from '../assets/images/whoweare/Corprate_WhoWeAre_03.jpg'
import tileimage3 from '../assets/images/whoweare/Corprate_WhoWeAre_04.jpg'
import tileimage4 from '../assets/images/whoweare/Corprate_WhoWeAre_05.jpg'
import { trackPageData, getPageDetails } from '../analytics'

class WhoWeAre extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Who We Are | The Children's Place Corporate Website"
          description="Learn more about The Children's Place on The Children's Place Corporate Website."
        />
        {/* Page Title & Header Image */}
        <div class="row mb-3">
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="WHO WE ARE"
            />
            <p class="text-center mb-5">
              We have achieved our success on the basis of a very simple
              principle: Trust.
              <br class="d-none d-md-inline-block" /> Wherever and whenever our
              customers choose to shop with us, they trust
              <br class="d-none d-md-inline-block" /> The Children’s Place,
              Gymboree, Sugar &amp; Jade and PJ Place to provide quality, value
              and style.
            </p>
          </div>
        </div>
        {/* End Page Title & Header Image */}

        <div class="row mb-5">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <Link to="/about-us">
              <Card image={tileimage1} title="ABOUT US" />
            </Link>
          </div>
          <div class="row d-md-none mb-5" style={{ width: 100 }}></div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <Link to="/senior-leadership">
              <Card
                image={tileimage2}
                title={['SENIOR ', <br />, 'LEADERSHIP']}
              />
            </Link>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <Link to="/board-of-directors">
              <Card image={tileimage3} title="BOARD OF DIRECTORS" />
            </Link>
          </div>

          <div class="row d-md-none mb-5" style={{ width: 100 }}></div>
          <div class="col-lg-6 col-md-6 col-sm-12 center-block">
            <Link to="/international-franchises">
              <Card image={tileimage4} title="INTERNATIONAL FRANCHISES" />
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WhoWeAre
